<template>
  <div class="container">
    <div class="about-us">
      <div class="foot-img"></div>
      <div class="content">
        <el-row style="font-size: 13px; text-align: center">
          <el-col :span="8">
            <el-image style="height: 70px" :src="require('../assets/logo-text.png')"> </el-image>
          </el-col>
          <el-col :span="16" class="out-url">
            <div v-for="(i, index) in outUrlList" :key="index">
              <p v-for="(v, num) in i.group" :key="num">
                <el-tooltip class="item" effect="dark" :content="v.url" placement="top-start">
                  <a :href="v.url" target="_blank">{{ v.title }}</a></el-tooltip
                >
              </p>
            </div>
          </el-col>
          <el-col :span="24"
            ><p>健康游戏忠告:抵制不良游戏，拒绝盗版游戏，注意自我保护，谨防受骗上当。适度济戏益脑，沉法游戏伤身。合理安排时间，享受健康生活。</p>
            <span>软著登字第1514161号ISBN978-7-7979-2949-3 新广出审[2016] 4412号</span>
            <br />
            <span>增值电信业务经营许可证 川B2-20100271</span> <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2022030354号</a></el-col>
        </el-row>
      </div>
      <div class="foot-img"></div>
    </div>
  </div>
</template>

<script>
const outUrlList = [
  {
    group: [
      {
        title: "茁安子脑超频",
        url: "http://www.droian.cc",
      },
      {
        title: "DIY tDCS",
        url: "http://www.diytdcs.cn",
      },
    ],
  },
  {
    group: [
      {
        title: "穆房产",
        url: "http://www.droian.cc",
      },
      {
        title: "熙雨丽禾",
        url: "http://www.diytdcs.cn",
      },
    ],
  },
  {
    group: [
      {
        title: "八点半测评",
        url: "http://www.diytdcs.cn",
      },
      {
        title: "回回旅游",
        url: "http://www.diytdcs.cn",
      },
    ],
  },
  {
    group: [
      {
        title: "宝多多",
        url: "http://www.diytdcs.cn",
      },
      {
        title: "虚实城",
        url: "http://www.diytdcs.cn",
      },
    ],
  },
  {
    group: [
      {
        title: "新月应相",
        url: "http://www.diytdcs.cn",
      },
      {
        title: "脑超频网店",
        url: "http://www.diytdcs.cn",
      },
    ],
  },
  {
    group: [
      {
        title: "清穆在线",
        url: "http://www.diytdcs.cn",
      },
    ],
  },
];
export default {
  data() {
    return {
      outUrlList: outUrlList,
    };
  },
};
</script>

<style scoped>
.container {
  width: 80%;
  margin: 0 auto;
  background: rgb(20, 22, 24);
  min-width: 1100px;
}
.about-us {
  width: 80%;
  min-width: 1100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 200px;
  background: rgb(20, 22, 24);
}
.foot-img {
  width: 13%;
  height: 100%;
}
.content {
  width: 84%;
  height: 100%;
}
.el-col {
  height: 70px;
  margin: 10px 0 0 0;
  color: #ffffff;
}
.out-url {
  text-align: left;
  display: flex;
}
a {
  color: #ffffff;
  text-decoration: none;
  margin-right: 10px;
}
a:hover {
  color: #afaeae;
}
.out-url > div > p {
  display: flex;
}
.out-url > div > p > a {
  display: block;
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
