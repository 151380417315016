import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import axios from 'axios';
import 'element-ui/lib/theme-chalk/index.css';
import VueQriously from 'vue-qriously'
import './assets/css/iconfont.css'; 
Vue.use(VueQriously)
Vue.use(ElementUI);
Vue.prototype.axios = axios;
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  beforeCreate(){
		Vue.prototype.$bus = this	//安装全局事件总线
	}
}).$mount('#app')
