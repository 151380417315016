<template>
  <div id="app">
    <el-container v-if="!isMobile">
      <el-header>
        <Header></Header>
      </el-header>
      <el-main>
        <keep-alive>
          <router-view />
        </keep-alive>
      </el-main>
      <el-footer>
        <Footer></Footer>
      </el-footer>
    </el-container>
    <el-container v-else>
      <MobileIndex />
    </el-container>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import MobileIndex from "./views/webMobilePay/MobileIndex.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
    MobileIndex
},
  data() {
    return {
      //判断手机和PC
      isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }
  }
};
</script>

<style scoped>
.el-header,
.el-main,
.el-footer {
  padding: 0;
}

.el-main {
  min-height: 80vh;
}

.el-header {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
}

.el-main {
  overflow: inherit;
}
</style>
