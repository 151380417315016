<template>
  <div class="container">
    <div class="header"></div>
    <div class="main">
      <template v-if="pageName == 'userinfo'">
        <div class="form-card">
          <div class="form-header">&ensp;基本信息</div>
          <el-form label-width="80px" :model="formUserInfo">
            <el-form-item label="用户号">
              <el-input disabled v-model="formUserInfo.userId"></el-input>
            </el-form-item>
            <el-form-item label="头像">
              <el-avatar
                disabled
                style="margin-left: 15px"
                shape="square"
                :src="formUserInfo.userImg"
              ></el-avatar>
            </el-form-item>
            <el-form-item label="昵称">
              <el-input disabled v-model="formUserInfo.userName"></el-input>
            </el-form-item>
            <el-form-item label="剩余卡数">
              <el-input disabled v-model="formUserInfo.cardCount"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="form-card">
          <div class="form-header">&ensp;实名信息</div>
          <el-form label-width="80px" :model="formTrueInfo">
            <el-form-item label="姓名">
              <el-input
                :class="nowReg ? 'show-input-border' : ''"
                :disabled="!nowReg || !formUserInfo.userId"
                v-model="formTrueInfo.trueName"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证号">
              <el-input
                :class="nowReg ? 'show-input-border' : ''"
                :disabled="!nowReg || !formUserInfo.userId"
                v-model="formTrueInfo.idCode"
                placeholder="请输入身份证号"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input
                :class="nowReg ? 'show-input-border' : ''"
                :disabled="!nowReg || !formUserInfo.userId"
                v-model="formTrueInfo.phone"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
            <el-button
              v-if="nowReg && formUserInfo.userId"
              type="primary"
              @click="onSubmit(formTrueInfo)"
              style="width: 100px; margin: 0 auto; display: block"
              >立即认证</el-button
            >
          </el-form>
        </div>
        <el-row style="text-align: center">
          <el-col :span="24">
            <el-button
              :disabled="nowReg || !formUserInfo.userId"
              @click="changePage('pay')"
              type="primary"
              round
              >已确认信息无误，前往充卡</el-button
            >
          </el-col>
        </el-row>
      </template>
      <template v-else-if="pageName == 'pay'">
        <div :class="autoClass">
          <el-button
            type="warning"
            plain
            v-for="(i, index) in roomCardList"
            :key="i.id"
            @click="selectItem(i.id, index)"
            :class="`${focusCardIndex == index ? 'active' : ''}`"
          >
            <h2>{{ i.title }}</h2>
            <p>价格：￥{{ i.price / 100 }}元</p>
          </el-button>
        </div>

        <el-row style="text-align: center">
          <el-col :span="24">
            <el-button
              @click="handlePay()"
              type="primary"
              icon="iconfont icon-weixin"
              round
              >微信支付</el-button
            >
            <el-button
              style="text-align: center; margin-left: 0"
              @click="changePage('userinfo')"
              type="text"
              round
              >返回</el-button
            >
          </el-col>
          <el-col :span="24">
            <!-- <p style="color: red; margin: 0; margin-top: 10px">
              微信支付正在审核，请到支付宝页面进行支付
            </p> -->
            <el-button type="text" @click="handlePay('ali')">前往支付宝充值</el-button>
          </el-col>
        </el-row>
      </template>
      <template v-else>
        <div class="form-card" style="margin-top: 20px">
          <div class="form-header">&ensp;请登录</div>
          <el-form label-width="80px" :model="loginForm">
            <el-form-item label="用户号">
              <el-input v-model="loginForm.userId" placeholder="请输入用户号"></el-input>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input v-model="loginForm.trueName" placeholder="姓名"></el-input>
            </el-form-item>
            <el-form-item label="身份证号">
              <el-input v-model="loginForm.idCode" placeholder="身份证号"></el-input>
            </el-form-item>
            <el-button
              type="primary"
              @click="onLogin(loginForm)"
              style="width: 180px; margin: 0 auto; display: block"
              >登录</el-button
            >
          </el-form>
        </div>
      </template>
    </div>
    <div class="footer">
      <el-row style="font-size: 12px; line-height: 18px; text-align: center">
        <el-col :span="24">
          <span>软著登字第1514161号ISBN978-7-7979-2949-3</span>
          <br />
          <span>新广出审[2016] 4412号</span>
          <br />
          <span>增值电信业务经营许可证 川B2-20100271</span>
          <br />
          <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2022030354号</a>
        </el-col>
      </el-row>
    </div>
    <el-dialog
      :visible.sync="codeDialogVisible"
      :show-close="false"
      :width="winWidth + 'px'"
      center
      @close="closeDialog"
      title="请使用非微信的APP扫码支付"
    >
      <qriously :value="codeUrl" :size="winWidth - 50" />
      <!-- :href="'weixin://dl/businessWebview/link/?url=' + codeUrl" -->
      <el-link
        style="display: block; text-align: center; font-size: 20px; margin: 0"
        :href="codeUrl"
        target="_blank"
        type="primary"
        round
        >前往微信APP支付</el-link
      >
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nowReg: false, //需要实名
      pageName: "",
      payBtnDisabled: false, //确认支付按钮是否禁用
      roomCardList: [],
      codeDialogVisible: false,
      payOrder: {
        //订单信息
        productId: "", //商品id
        payType: "wxpay", //支付方式
      },
      codeUrl: "", // 二维码
      orderNo: "", //订单号
      timer: null, // 定时器
      focusCardIndex: 0,
      btnMsg: "已确认信息无误，前往充卡",
      formUserInfo: {
        userId: "",
        userImg: "",
        userName: "",
        cardCount: null,
      },
      formTrueInfo: {
        trueName: "",
        idCode: "",
        phone: "",
      },
      loginForm: {
        userId: "",
        trueName: "",
        idCode: "",
      },
      userToke: {},
    };
  },
  computed: {
    winWidth() {
      return String(document.body.offsetWidth - 50);
    },
    autoClass() {
      return window.outerHeight < 700 ? "room-card-list-mini" : "room-card-list";
    },
  },
  created() {
    let params = window.location.search,
      tkstr = "",
      obj = JSON.parse(localStorage.getItem("daoUserInfo"));
    if (params.includes("id=") && params.includes("tk=")) {
      localStorage.removeItem("daoUserInfo");
      this.pageName = "userinfo";
      tkstr = params.split("id=")[1].split("&tk=");
      this.userToke = {
        uidi: tkstr[0],
        token: tkstr[1],
      };
      this.autoLogin(this.userToke);
    } else if (obj) {
      this.pageName = "userinfo";
      this.formUserInfo = {
        userId: obj.uidi,
        userImg: obj.headc,
        userName: obj.nicknamec,
        cardCount: obj.cardsi,
      };
      this.formTrueInfo = {
        trueName: obj.truenamec,
        idCode: obj.idcardc,
        phone: obj.phonec,
      };
    }
    //获取商品列表
    this.axios.get("http://146.56.202.110:8090/api/product/list").then((res) => {
      if (res.status === 200) {
        this.roomCardList = res.data.data.productList;
        this.payOrder.productId = res.data.data.productList[0].id;
      } else {
        this.$message.error({
          message: "充值信息获取失败",
          center: true,
        });
      }
    });
  },
  methods: {
    async onLogin(loginForm) {
      if (!loginForm.userId) {
        return this.$message.warning({
          message: "请输入用户号",
          center: true,
        });
      }
      if (!loginForm.trueName) {
        return this.$message.warning({
          message: "请输入姓名",
          center: true,
        });
      }
      if (!loginForm.idCode) {
        return this.$message.warning({
          message: "请输入身份证号",
          center: true,
        });
      }
      let res = await this.axios.get(
        `http://146.56.202.110:8090/api/user/identity/logon/${loginForm.userId}/${loginForm.trueName}/${loginForm.idCode}`
      );
      if (res.data.code == 0) {
        this.pageName = "userinfo";
        let obj = res.data.data.userInfo;
        localStorage.setItem("daoUserInfo", JSON.stringify(obj));
        this.formUserInfo = {
          userId: obj.uidi,
          userImg: obj.headc,
          userName: obj.nicknamec,
          cardCount: obj.cardsi,
        };
        this.formTrueInfo = {
          trueName: obj.truenamec,
          idCode: obj.idcardc,
          phone: obj.phonec,
        };
        if (!obj.idcardc) {
          this.nowReg = true;
          this.$message.warning({
            message: "非法用户无法充值,请先完成实名认证",
            center: true,
          });
        } else {
          this.$message.success({
            message: "登录成功",
            center: true,
          });
        }
      } else {
        this.$message.error({
          message: "用户信息获取失败",
          center: true,
        });
      }
    },
    async autoLogin(userToke) {
      if (!userToke.uidi || !userToke.token) {
        this.nowReg = true;
        this.$message.error({
          message: "用户信息获取失败",
          center: true,
        });
        return;
      }
      if (userToke.token == 0)
        return this.$message.error({
          message: "登录信息已过期，请重新登陆",
          center: true,
        });
      let res = await this.axios.get(
        `http://146.56.202.110:8090/api/user/token/logon/${userToke.uidi}/${userToke.token}`
      );
      if (res.data.code == 0) {
        let obj = res.data.data.userInfo;
        localStorage.setItem("daoUserInfo", JSON.stringify(obj));
        this.formUserInfo = {
          userId: obj.uidi,
          userImg: obj.headc,
          userName: obj.nicknamec,
          cardCount: obj.cardsi,
        };
        this.formTrueInfo = {
          trueName: obj.truenamec,
          idCode: obj.idcardc,
          phone: obj.phonec,
        };
        if (!obj.idcardc) {
          this.nowReg = true;
          this.$message.warning({
            message: "非法用户无法充值,请先完成实名认证",
            center: true,
          });
        }
      } else {
        this.$message.error({
          message: "用户信息获取失败,请手动登录",
          center: true,
        });
        this.pageName = "login";
        this.loginForm.userId = userToke.uidi;
      }
    },
    // 身份证校验
    isIdCardValid(idCard) {
      let pattern = /^[1-9]\d{5}(18|19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[1-2]\d|3[0-1])\d{3}(\d|X|x)$/,
        regex = new RegExp(pattern);
      return regex.test(idCard);
    },
    //手机号校验
    isPhoneValid(phone) {
      let pattern = /^1[3456789]\d{9}$/,
        regex = new RegExp(pattern);
      return regex.test(phone);
    },
    async onSubmit(form) {
      if (!form.trueName)
        return this.$message.warning({
          message: "请输入姓名",
          center: true,
        });
      if (!form.idCode)
        return this.$message.warning({
          message: "请输入身份证号",
          center: true,
        });
      if (!this.isIdCardValid(form.idCode))
        return this.$message.warning({
          message: "身份证号不合法",
          center: true,
        });
      if (!form.phone)
        return this.$message.warning({
          message: "请输入手机号",
          center: true,
        });
      if (!this.isPhoneValid(form.phone))
        return this.$message.warning({
          message: "手机号不合法",
          center: true,
        });
      let userToke = this.userToke,
        res = await this.axios.get(
          `http://146.56.202.110:8090/api/user/bind/identity/${userToke.uidi}/${userToke.token}/${form.trueName}/${form.idCode}/${form.phone}`
        );
      if (res.data.code == 0) {
        this.$message.success({
          message: "认证成功",
          center: true,
        });
        window.location.reload();
      }
    },
    changePage(page) {
      if (!this.nowReg) {
        this.pageName = page;
      } else {
        this.$message.warning({
          message: "非法用户无法充值,请先完成实名认证",
          center: true,
        });
      }
    },
    selectItem(productId, index) {
      this.focusCardIndex = index;
      this.payOrder.productId = productId;
    },
    handlePay(type) {
      let username = localStorage.getItem("daoUserInfo");
      if (!username) {
        this.$message.warning({
          message: "非法用户无法充值",
          center: true,
        });
      } else {
        if (type == "ali") {
          this.toAlipay();
        } else {
          this._pay(this.payOrder.productId);
        }
      }
    },
    _pay(id) {
      this.payBtnDisabled = true;
      let userId = JSON.parse(localStorage.getItem("daoUserInfo")).uidi;
      this.axios
        .post(`http://146.56.202.110:8090/api/wx-pay/native/${id}/${userId}/h5`)
        .then((res) => {
          if (res.status === 200) {
            this.codeUrl = res.data.data.codeUrl;
            this.orderNo = res.data.data.orderNo;
            window.open(this.codeUrl, "_blank");
            //打开二维码弹窗  手机上打开app支付
            // this.codeDialogVisible = true;
            //启动定时器
            this.timer = setInterval(() => {
              //查询订单是否支付成功
              this.queryOrderStatus(this.orderNo);
            }, 3000);
          }
        });
    },
    toAlipay() {
      let userId = JSON.parse(localStorage.getItem("daoUserInfo")).uidi;
      const url = `http://pay.viyo.me/index?id=${userId}`;
      window.open(url, "target");
    },
    // 查询订单状态
    queryOrderStatus(orderNo) {
      this.axios
        .get(`http://146.56.202.110:8090/api/order-info/query-order-status/${orderNo}`)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 0) {
              this.codeDialogVisible = false;
              this.$message.success({
                message: "支付成功",
                center: true,
              });
            }
          } else {
            this.$message.error({
              message: "查询订单失败，请联系管理员",
              center: true,
            });
          }
        });
    },
    closeDialog() {
      this.payBtnDisabled = false;
      clearInterval(this.timer);
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  overflow-y: scroll;
}

a {
  color: #484848;
  text-decoration: none;
  margin-right: 10px;
}

.header {
  height: 20px;
  /* background-image: url('../../assets/mobileHeaderBg.png');
  background-size: contain;
  background-repeat: no-repeat;
  padding-bottom: 10px; */
}

.main {
  margin-bottom: 30px;
}

.el-button {
  width: 80%;
}

.form-card {
  width: 90%;
  margin: 0 auto;
  border-radius: 7px;
  box-shadow: 0 0px 0px 0 rgba(0, 4, 4, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
}

.el-form {
  padding-bottom: 10px;
  margin-bottom: 20px;
}

::v-deep .el-input__inner {
  border: none;
  height: 30px;
}

::v-deep .show-input-border .el-input__inner {
  border: 1px solid #dcdfe6;
}

::v-deep .el-form-item {
  margin-bottom: 5px;
}

.el-input {
  width: 90%;
}

::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #ffffff00 !important;
}

.form-header {
  background-color: #409eff;
  height: 28px;
  line-height: 28px;
  border-radius: 7px 7px 0 0;
  color: #fff;
  font-size: 14px;
}

.room-card-list,
.room-card-list-mini {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.room-card-list .el-button {
  margin-bottom: 20px;
  padding: 20px;
  margin-left: 0;
}

.room-card-list-mini .el-button {
  margin-bottom: 10px;
  margin-left: 0;
}

.active {
  background: #e6a23c !important;
  border-color: #e6a23c !important;
  color: #fff !important;
}

::v-deep .el-dialog--center .el-dialog__body {
  text-align: center;
  padding-top: 0px;
}
</style>
