import { render, staticRenderFns } from "./MobileIndex.vue?vue&type=template&id=49008811&scoped=true"
import script from "./MobileIndex.vue?vue&type=script&lang=js"
export * from "./MobileIndex.vue?vue&type=script&lang=js"
import style0 from "./MobileIndex.vue?vue&type=style&index=0&id=49008811&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49008811",
  null
  
)

export default component.exports